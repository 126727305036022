// import { Atom, Mod, log } from ":mods";
// import { TP04Task01 } from ".";
import { TopicTitle } from "./components";
import { For, Match, Show, Switch, createEffect, createSignal, splitProps } from "solid-js";
import { MaterialTaskRouter } from "./material-task-router";
import { ShowMaterialTaskView } from "../../../models";
import { Atom, log } from ":mods";

export function ShowMaterialTask(props: ShowMaterialTaskView) {
  const [local, others] = splitProps(props, ["$", "events", "elements", "options"]);
  if (local.elements?.data === undefined || local.elements?.data === null) {
    throw new Error("ShowMaterialTask: data cannot be undefined or null!");
  }
  const design_name = local.elements.data.design_name;
  const [stepNum, setStepNum] = createSignal(local.options?.showState === "show_only" ? design_name : "0");
  const topicNumber = design_name?.split("_")?.[0]?.replace("tp", "Topic ") ?? "Topic 01";
  const taskNumber = design_name?.split("_")?.[1]?.replace("task", "Task ") ?? "Task 01";
  const topicData = local.elements.data;
  const tips: string[] = topicData.tips?.split("$$") ?? [""];
  local.$.actions.setLayout({
    titleBorder: true,
  });
  createEffect(() => {
    if (stepNum() === "0") {
      local.$.actions.updateLayout({
        title: () => <TopicTitle title={topicData.name} topicNumber={topicNumber} taskNumber={taskNumber} />,
      });
    }
  });
  const handleTaskRedirect = (activateTask: boolean) => {
    // console.log("called handle task back :: ", activateTask);
    if (activateTask) {
      // log.all.info("activating task name :: ", task_name);
      setStepNum(design_name);
    } else {
      setStepNum("0");
    }
  };

  return (
    <section class="px-2">
      <Switch fallback={null}>
        <Match when={stepNum() === "0"}>
          <article class="flex flex-col gap-20px mt-40px py-20px !text-16px">
            <p class="font-700 leading-24px">Description:</p>
            <p class="leading-22.4px">{topicData.description}</p>
            <div id="tip-container" class="flex gap-10px bg#n-50 p-10px">
              <i class="icon-local:tip-blue w-24px h-24px block" />
              <div class="flex flex-col space-y-4">
                <For each={tips}>
                  {(item, index) => {
                    return <p>{item}</p>;
                  }}
                </For>
              </div>
            </div>
            <div class="flex justify-end w-full">
              <Atom.Buttons.Button
                text="Start Task"
                onClick={() => handleTaskRedirect(true)}
                theme="dark"
                cls="!w-110px "
                link=""
              />
            </div>
          </article>
        </Match>

        <Match when={stepNum() !== "0"}>
          <MaterialTaskRouter
            $={local.$}
            elements={{
              data: topicData,
            }}
            options={local.options}
            events={local.events}
          />
        </Match>
      </Switch>
    </section>
  );
}
